import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  CallToActionBanner,
  OutboundLink,
  PaginatedGrid,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Button, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <div>
              <div>
                <div className={"yt-container"}>
                  <iframe
                    width={"100%"}
                    height={"700"}
                    src={
                      "https://player.vimeo.com/video/798879975?autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
                    }
                    frameborder={"0"}
                    allow={"autoplay; fullscreen; picture-in-picture"}
                    allowfullscreen={false}
                  />
                  <div className={"hero-logo"}>
                    <Image
                      centered={true}
                      size={"medium"}
                      src={
                        "https://fisherman.gumlet.io/public/e0eb2271-4a9a-4a23-ad43-84027156406e/whitelogo_1.png?width=500"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true}>
            <CallToActionBanner.CTA>
              <Button
                as={OutboundLink}
                to={fishermanBusiness.ctas.orderOnline.link}
                primary={true}
                content={"Order Now"}
                size={"large"}
              />
            </CallToActionBanner.CTA>
          </CallToActionBanner>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { author: "author", text: "text" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        orderOnline {
          link
        }
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
